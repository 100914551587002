<template>
<div
    ref="globe_container"
    :class="['globe-container height-100 width-100', loaded ? 'globe-container-ready' : '']"
    @wheel="zoomChange"
    @click="clickEvent"
  >
  <canvas ref="globe" />
</div>
</template>

<style>
.globe-container {
  opacity: 0;
  transition: opacity 350ms;
}
.globe-container.globe-container-ready {
  opacity: 1;
}

</style>

<script>
import { gsap } from 'gsap'
import * as THREE from 'three'
import {ConvexGeometry} from 'three/examples/jsm/geometries/ConvexGeometry'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils'

const getPos = (lat, lon, radius) => {
  var phi   = (90-lat)*(Math.PI/180)
  var theta = (lon+180)*(Math.PI/180)
  const x = -(radius * Math.sin(phi)*Math.cos(theta))
  const z = (radius * Math.sin(phi)*Math.sin(theta))
  const y = (radius * Math.cos(phi))
  return [x,y,z]
}

export default {
  name: "EarthChart",
  data: () => ({
    loaded: false,
    currentZoom:0,
    scene: undefined,
    renderer: undefined,
    camera: undefined,
    canvas: undefined,
    points: [],
    origin: [0,0],
    zoomOrigin: 0,
    need_populate: false,
    //
    group: undefined,
    decorative: undefined,
    light: undefined,
    earth: undefined,
    clouds: undefined,
    // particles: undefined,
  }),
  props: {
    values: {
      type: Array,
      default: () => ([])
    },
    zoom: {
      type: Number,
      default: 0
    },
    move: {
      type: Array,
      default: () => ([0,0])
    },
  },
  watch: {
    zoom (n) {
      if (n !== this.currentZoom) {
        this.zoomChange(undefined, n)
      }
    },
    move (n) {
      if (n[0] !== 0) this.group.rotation.y += (n[0]/800) * (Math.PI/180)
      if (n[1] !== 0) this.group.rotation.x += (n[1]/800) * (Math.PI/180)
    },
    values: {
      deep: true,
      handler(n) {
        // console.log('VALUES CHANGED', n)
        this.need_populate = true
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
    this.scene.clear()
  },
  mounted () {
    window.addEventListener('resize', this.resize, false)
    this.createScene()

  },
  methods: {
    clickEvent (e) {
      console.log('clickEvent', e)
    },
    zoomChange (e, n) {
      this.currentZoom = n

      let x, y , z, intensity
      if (this.currentZoom >= 3) {
        this.currentZoom = 3
        x = -52
        y = 5
        z = 200
        intensity = 1.3
      } else if (this.currentZoom >= 2) {
        this.currentZoom = 2
        x = -60
        y = 3
        z = 250
        intensity = 1.5
      } else if (this.currentZoom >= 1) {
        this.currentZoom = 1
        x = -72
        y = 3
        z = 350
        intensity = 2
      } else {
        this.currentZoom = 0
        x = -85
        y = 3
        z = 450
        intensity = 2
      }

      gsap.fromTo(
        this.light,
        {
          intensity: this.light.intensity || 1
        },
        {
          intensity,
          duration: .3
        }
      )

      gsap.fromTo(
        this.camera.position,
        {
          x: this.camera.position.x,
          y: this.camera.position.y,
          z: this.camera.position.z,
        },
        {
          x,
          y,
          z,
          duration: 0.5
        })

      this.$emit('zoom', this.currentZoom)
    },

    resize () {
      const width = this.$refs.globe_container.clientWidth
      const height =  this.$refs.globe_container.clientHeight
      this.canvas.height = height
      this.canvas.width = width
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(width, height)
      this.render()
    },

    // convert the positions from a lat, lon to a position on a sphere.
    latLongToVector3(lat, lon, radius, heigth) {
      const phi = (lat)*Math.PI/180
      const theta = (lon-180)*Math.PI/180
      const x = -(radius+heigth) * Math.cos(phi) * Math.cos(theta)
      const y = (radius+heigth) * Math.sin(phi)
      const z = (radius+heigth) * Math.cos(phi) * Math.sin(theta)
      return new THREE.Vector3(x,y,z)
    },

    animate () {
      requestAnimationFrame(this.animate)

      if (this.need_populate) this.populate()
      const DELAY = 0.0003

      if (this.origin[0] !== this.group.rotation.x) {
        const dif = this.origin[0] > this.group.rotation.x ? this.origin[0] - this.group.rotation.x : this.group.rotation.x - this.origin[0]
        if (Math.abs(dif) > DELAY) this.group.rotation.x += DELAY * (this.origin[0] > this.group.rotation.x ? 1 : -1)
        else this.group.rotation.x = this.origin[0]
      }
      if (this.origin[1] !== this.group.rotation.y) {
        const dif = this.origin[1] > this.group.rotation.y ? this.origin[1] - this.group.rotation.y : this.group.rotation.y - this.origin[1]
        if (Math.abs(dif) > DELAY) this.group.rotation.y += DELAY * (this.origin[1] > this.group.rotation.y ? 1 : -1)
        else this.group.rotation.y = this.origin[1]
      }

      // this.group.rotation.y -= 0.00015
      // this.earth.rotation.y -= 0.01
      // this.clouds.rotation.y -= 0.0002
      // this.decorative.rotation.y -= 0.002
      // this.decorative.rotation.x -= 0.002
      // this.clouds.rotation.x -= 0.0001

      this.render()

    },

    render() {
      this.renderer.render(this.scene, this.camera)
    },

    rotate (a,b,decal = 0.6) {
      var c = this.group.rotation.y
      var d = -b * (Math.PI / 180) % (2 * Math.PI)
      var e = Math.PI / 2 * - 1
      let y = c % (2 * Math.PI)
      let x = a * (Math.PI / 180) % Math.PI
      y = d + e - decal
      this.origin = [x, y]
      gsap.fromTo(
        this.group.rotation,
        {
          x: this.group.rotation.x,
          y: this.group.rotation.y,
        },
        {
          x,
          y,
          duration: 5
        }
      )
      // gsap.fromTo(
      //   this.origin,
      //   {
      //     0: this.origin[0],
      //     1: this.origin[1],
      //   },
      //   {
      //     0: a * (Math.PI / 180) % Math.PI,
      //     1: d + e - decal,
      //     duration: .8
      //   }
      // )
    },


    populate () {
      this.need_populate = false
      const values = this.values
      let lats = 0
      let lons = 0
      let vmax = 0
      for (let i = 0, len = values.length; i< len; i++) {
        const value = values[i]
        if (value.value > vmax) vmax = value.value
      }

      const sizes = []
      const vertices = []
      const points = {}
      for (let i = 0, len = values.length; i< len; i++) {
        const value = values[i]
        const visibility = value.value / vmax
        lats += value.lat
        lons += value.lon

        const pos = getPos(value.lat, value.lon, 90)
        const vertex = new THREE.Vector3(pos[0], pos[1], pos[2])

        const pos2 = getPos(value.lat, value.lon, 90 + 10)
        const vertex2 = new THREE.Vector3(pos2[0], pos2[1], pos2[2])

        const key = ''+value.value
        if (!points[key]) {
          points[key] = {
            vertices: [vertex],
            vertices2: [vertex2],
            size: visibility,
          }
        } else {
          points[key].vertices.push(vertex)
          points[key].vertices2.push(vertex2)
        }

      }
        // const points = []
        // points.push(new THREE.Vector3( 0, 0, 32))
        // points.push(new THREE.Vector3( 0, 0, 0))
        // const geometry = new THREE.BufferGeometry().setFromPoints(points)
        // const material = new THREE.LineBasicMaterial({
        //   color: 0xFFFFFF,//0xE0BC37,
        //   linewidth: 1, //2 * visibility + 2,
        //   // opacity: 0.2 * visibility + 0.3,
        //   // transparent: true,
        // })

        // const pos = getPos(value.lat, value.lon, 90 + (30 * visibility + 2))
        // const line = new THREE.Line( geometry, material)
        // line.position.x = pos[0]
        // line.position.y = pos[1]
        // line.position.z = pos[2]
        // line.lookAt(new THREE.Vector3(0,0,0))
        // // line.scale.set(2 * visibility + 1, 2 * visibility + 1, 2 * visibility + 1)
        // this.group.add(line)

        // const pos2 = getPos(value.lat, value.lon, 91.2)
        // const geometry2 = new THREE.CircleGeometry(1 * visibility + 0.1, 12)
        // const material2 = new THREE.MeshBasicMaterial({
        //   side: THREE.DoubleSide,
        //   color: 0xE0BC37,
        //   // linewidth: 2 * visibility + 2,
        //   // opacity: 0.5 * visibility + 0.5,
        //   // transparent: true,
        // })
        // const cylinder = new THREE.Mesh( geometry2, material2)
        // cylinder.position.x = pos2[0]
        // cylinder.position.y = pos2[1]
        // cylinder.position.z = pos2[2]
        // cylinder.lookAt(new THREE.Vector3(0,0,0))
        // // cylinder.rotation.y += 90 * (Math.PI/180)
        // this.group.add(cylinder)

      // }
      const particles = this.particles
      particles.clear()
      // console.log(particles)
      for (let i = particles.children.length - 1; i >= 0; i--) {
        particles.remove(particles.children[i])
      }
      for (const key of Object.keys(points)) {
        const point = points[key]
        // console.log(point)





        // const material = new THREE.LineBasicMaterial( {
        // 	color: 0xe0bc37,
        // 	linewidth: 30 + 30 * point.size,
        // 	linecap: 'round', //ignored by WebGLRenderer
        // 	linejoin:  'round' //ignored by WebGLRenderer
        // })
        // const material2 = new THREE.MeshLambertMaterial( {
        // 	color: 0xe0bc37,
        //   shininess: 100,
        //   // transparent: true,
        //   opacity: .5 + point.size/2,
        //   blending: THREE.AdditiveBlending
        // })

        // for (let i = 0, len = point.vertices.length; i< len; i++) {
        //   const x = point.vertices[i].x + point.vertices2[i].x * point.size/4
        //   const y = point.vertices[i].y + point.vertices2[i].y * point.size/4
        //   const z = point.vertices[i].z + point.vertices2[i].z * point.size/4
        //   const points = [
        //     new THREE.Vector3(point.vertices[i].x, point.vertices[i].y, point.vertices[i].z ),
        //     new THREE.Vector3(x, y, z)
        //   ]
        //   const geometry = new THREE.BufferGeometry().setFromPoints( points )
        //   const line = new THREE.Line(geometry, material)
        //   // particles.add(line)
        //   const ratio = 1 + 9 * point.size
        //   const geometry2 = new THREE.CylinderGeometry(0.5, 0.5, point.size, 8)
        //   const mesh = new THREE.Mesh(geometry2, material2)
        //   mesh.position.x = point.vertices[i].x //+ (point.vertices2[i].x - point.vertices[i].x) / 2 // *  (20 * point.size)
        //   mesh.position.y = point.vertices[i].y //+ (point.vertices2[i].y - point.vertices[i].y) / 2 // *  (20 * point.size)
        //   mesh.position.z = point.vertices[i].z //+ (point.vertices2[i].z - point.vertices[i].z) / 2 // *  (20 * point.size)
        //   mesh.lookAt(new THREE.Vector3(0,0,0))
        //   mesh.rotation.y += 90 * (Math.PI/180)
        //   particles.add(mesh)
        //
        // }


      // for (const key of Object.keys(points)) {
        // const point = points[key]
        const material = new THREE.SpriteMaterial({
          side: THREE.DoubleSide,
          color: 0xE0BC37,
          map: this.textures.sparkle,
          opacity:1,
          // opacity: 0.7 * point.size + 0.3,
          depthTest: false,
          sizeAttenuation: true,
          blending: THREE.AdditiveBlending
        })
        material.color.setHSL( (20+27*point.size)/360, 0.73, 0.55 )
        // material.color = new THREE.Color('hls(47, 73%, 55%)')
        // const material2 = new THREE.SpriteMaterial({
        //   // side: THREE.DoubleSide,
        //   // color: 0xE0BC37,
        //   map: this.textures.sparkle,
        //   opacity: 0.7 * point.size + 0.3,
        //   depthTest: false,
        //   transparent: true,
        // })
        for (let i = 0, len = point.vertices.length; i< len; i++) {

          const sprite = new THREE.Sprite(material)
          sprite.lookAt(new THREE.Vector3(0,0,0))
          sprite.position.set(point.vertices[i].x, point.vertices[i].y, point.vertices[i].z)
          const scale = 8 * point.size + 1
          sprite.scale.set(scale, scale, scale)
          particles.add(sprite)

          // const sprite2 = new THREE.Sprite(material2)
          // sprite2.lookAt(new THREE.Vector3(0,0,0))
          // sprite2.position.set(point.vertices[i].x, point.vertices[i].y, point.vertices[i].z)
          // const scale2 = 3 * point.size + 1
          // sprite2.scale.set(scale2, scale2, scale2)
          // particles.add(sprite2)


        }

      }

      const x = lats/values.length, y = lons/values.length
      if (!isNaN(x) && !isNaN(y)) this.rotate(x, y)
    },

    createScene () {
      console.log('CREATE SCENE')
      // texture
      const textures = {}
      const loader = (path) => new Promise((resolve, reject) => {
        let name = path.split('/')
        name = name[name.length-1]
        name = name.split('.')[0]
        console.info('LOAD:', name, ' | ', path)
        const error = (e) => {
          console.log('LOAD FAILED:', name)
          reject(e)
        }
        const success = (t) => {
          console.log('LOAD SUCCEED:', name)
          resolve(t)
        }
        textures[name] = new THREE.TextureLoader().load(path, success, undefined, error)
      })
      const paths = [
        // "/img/earth/earth_clouds_1.png",
        // "/img/earth/earth_clouds_2.png",
        // "/img/earth/earth_clouds_bump.jpg",
        // "/img/earth/earth_specular.jpg",
        "/img/earth/earth_map.png",
        // "/img/earth/earth_bump.jpg",
        // "/img/earth/dot.png",
        "/img/earth/sparkle.png",
        // "/img/earth/earth_dot.png",
      ]
      const loadTextures = () => Promise.all(paths.map(path => loader(path)))

      // couple of constants
      const fov = 20
      const near = 0.1
      const far = 10000
      const earthSize = 90

      // create a basic scene and add the camera
      const scene = new THREE.Scene()
      this.scene = scene

      // fog
      const fogColor = new THREE.Color(0x201F35)
      scene.fog = new THREE.FogExp2({
        color: fogColor,
        density: 90.0
      })

      const group = new THREE.Group()
      this.group = group
      scene.add(group)

      // particles
      const particles = new THREE.Group()
      this.particles = particles
      this.group.add(particles)

      // add the earth
      const addEarth = () => {
        // change texture bumpMap  mapBump.anisotropy = 1;
        // textures.earth_bump.repeat.set( 1, 1 )
        // textures.earth_bump.offset.set( 0, 0 )
        // textures.earth_bump.wrapS = textures.earth_bump.wrapT = THREE.RepeatWrapping
        // textures.earth_bump.format = THREE.RGBFormat

        // earth geometry
        const earthGeometry = new THREE.SphereGeometry(earthSize, 64, 64)
        // earth material
        const earthMaterial = new THREE.MeshPhongMaterial({
          color : 0x777777,
          // side: THREE.DoubleSide,
          shininess: 50,
          map: textures.earth_map,
          transparent: true,
          // specularMap: textures.earth_specular,
          // bumpMap: textures.earth_bump,
          // bumpScale: 1,
        })
        // earth mesh
        const earth = new THREE.Mesh(earthGeometry, earthMaterial)
        earth.rotation.y = -90 * (Math.PI/180)
        group.add(earth)

        this.earth = earth
      }

      // add the earth
      const addDecorative = () => {
        // // earth geometry
        // const decorativeGeometry = new THREE.BoxGeometry(earthSize * 1.1, earthSize * 1.1, earthSize * 1.1)
        // // decorative material
        // const decorativeMaterial = new THREE.MeshPhongMaterial({
        //   wireframe: true,
        //   opacity:.02,
        //   transparent: true,
        // })
        // // decorative mesh
        // const decorative = new THREE.Mesh(decorativeGeometry, decorativeMaterial)
        // group.add(decorative)
        //
        // this.decorative = decorative
      }

      const addClouds = () => {
        const clouds = new THREE.Group()

        // // cloud Geometry
        // const cloudGeometry1 = new THREE.SphereGeometry(earthSize+earthSize/100, 64, 64)
        // const cloudGeometry2 = new THREE.SphereGeometry(earthSize+earthSize/75, 64, 64)
        // // cloud metarial
        // const cloudMetarial1 = new THREE.MeshPhongMaterial({
        //   side: THREE.DoubleSide,
        //   map: textures.earth_clouds_1,
        //   shininess: 200,
        //   opacity:0.8,
        //   transparent: true,
        //   bumpMap: textures.earth_clouds_bump,
        //   bumpScale: 1,
        // })
        // const cloudMetarial2 = new THREE.MeshPhongMaterial({
        //   side: THREE.DoubleSide,
        //   map: textures.earth_clouds_2,
        //   shininess: 200,
        //   opacity:0.2,
        //   transparent: true,
        // })

        // cloud mesh
        // clouds.add(new THREE.Mesh(cloudGeometry1, cloudMetarial1))
        // clouds.add(new THREE.Mesh(cloudGeometry2, cloudMetarial2))
        group.add(clouds)

        this.clouds = clouds
      }

      // add a simple light
      const addLights = () => {
        // ambient light
        const ambientlight = new THREE.AmbientLight(0xffffff, 0.3)
        scene.add(ambientlight)
        // point light
        const pointLight = new THREE.PointLight(0xffffff, 1.5)
        pointLight.position.set(-200, 100, 200)
        pointLight.intensity = 1
        scene.add(pointLight)
        this.light = pointLight
      }

      const addRenderer = () => {
        // some global variables and initialization code
        // simple basic renderer
        const width = this.$refs.globe_container.clientWidth
        const height = this.$refs.globe_container.clientHeight
        const pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
        const canvas = this.$refs.globe
        canvas.height = height
        canvas.width = width
        this.canvas = canvas
        const renderer = new THREE.WebGLRenderer({alpha: true, canvas: canvas, antialias: true})
        renderer.setSize(width,height)
        renderer.setPixelRatio(pixelRatio)
        renderer.autoClear = false
        renderer.setClearColor(0x000000, 0.0)
        this.renderer = renderer
      }

      const addCamera = () => {
        // setup a camera that points to the center
        const aspect = this.$refs.globe_container.clientWidth / this.$refs.globe_container.clientHeight
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
        camera.lookAt(new THREE.Vector3(0,0,0))
        camera.position.x = -55
        camera.position.y = 5
        camera.position.z = 200
        scene.add(camera)
        this.camera = camera
      }

      // // we wait until the document is loaded before loading the
      // // density data.
      console.log('LOAD')
      loadTextures()
        .then(() => {
          console.log('LOADED')
          this.textures = textures
          addLights()
          addEarth()
          addDecorative()
          addClouds()
          addCamera()
          addRenderer()
          this.zoomChange(undefined, this.zoom || 0)
          this.loaded = true
          this.animate()
        })
        .catch(e => {
          console.error(e)
        })

    },


  }

}
</script>
